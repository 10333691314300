import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Pagination, Select } from "antd";

import InventoryFilterSection from "./InventoryFilters";
import InventoryTableList from "./InventoryTable";
import InventoryModal from "./InventoryModal";
import TableEditor from "../../../Common/TableEditor";
import CascadingSort from "../../../Common/CascadingSort";
import SavedSearchPresetsModal from "../../../Common/SavedSearchPresetsModal";
import { SelectionModelComp } from "../../../../Containers";
import LanguagesModal from "./../../../Common/Components/LanguagesModal";
import SaveSearchModal from "./../../../Common/Components/SaveSearchModal";
import InventoryGrid from "./InventoryGrid";
import TableColumns from "./../../../../Containers/TableEditor";
import {
  addKeyInArray,
  getRolesPermissions,
} from "../../../Common/reUseFunctions";

const { Option } = Select;

const InventorySearch = ({
  mainUserRes,
  assetsList,
  onGetAssetDetail,
  assetDetail,
  onGetMarketReady,
  marketList,
  sessionRes,
  inventoryType,
  inventoryListRes,
  onGetInventoryList,
  updateInventoryHeaderRes,
  onUpdateInventoryHeader,
  onGetInventoryHeader,
  getInventoryHeaderRes,
  addAssetInSelectionRes,
  onAddAssetInSelection,
  getInventorySeachDetailRes,
  onGetInventorySeachDetail,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleTE, setVisibleTE] = useState(false);
  const [visibleLanguages, setVisibleLanguages] = useState(false);
  const [selectionModel, setSelectionModel] = useState(false);
  const [saveSearch, setSaveSearch] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [marketReadyList, setMarketReadyList] = useState([]);
  const [saveSearchResult, setSaveSearchResult] = useState([]);
  const [visiblePopover, setVisiblePopover] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [changeLayout, setChangeLayout] = useState("LIST");
  const [changeInventory, setChangeInventory] = useState("My");
  const [search, setSearch] = useState("");
  const [inventoryBothArr, setInventoryBothArr] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [orderDisorder, setorderDisorder] = useState(true);
  const [directorShow, setDirectorShow] = useState([]);
  const [assetId, setAssetId] = useState();
  const [cascadingSort, setCascadingSort] = useState("");
  const [itemsLimit, setItemsLimit] = useState(10);

  useEffect(() => {
    if (getRolesPermissions(mainUserRes, "inventory.list") === false) {
      if (changeInventory === "My") {
        getMarketReady("local", itemsLimit);
      }

      if (changeInventory === "Market") {
        getMarketReady("market", itemsLimit);
      }

      if (changeInventory === "Both") {
        getMarketReady("", itemsLimit);
      }
    }
  }, [
    changeInventory,
    itemsLimit,
    currentPage,
    search,
    searchResult,
    cascadingSort,
    orderDisorder,
    mainUserRes,
  ]);

  // useEffect(() => {
  //   if (changeInventory === 'Both') {
  //     mergeArr();
  //   }

  //   if (changeInventory === 'Market') {
  //     let data = marketReadyData();
  //     setMarketReadyList(data);
  //   }
  // }, [assetsList, marketList])

  // const mergeArr = () => {
  //   const origArr = assetsList && addKeyInArray(assetsList.result);
  //   const marketArr = marketReadyData();

  //   let newArr = []
  //   if (origArr && marketArr) {
  //     newArr = [...origArr, ...marketArr]
  //   }
  //   setInventoryBothArr(newArr)
  // }

  const getMarketReady = (inventoryType) => {
    const isStringify = cascadingSort ? JSON.stringify(cascadingSort) : "";
    const skip = currentPage === 1 ? 0 : (currentPage - 1) * 9;
    let order = orderDisorder === true ? "asc" : "desc";
    onGetInventoryList(
      search,
      itemsLimit,
      skip,
      inventoryType,
      searchResult,
      isStringify,
      order
    );
  };

  const onChangeLayout = (e) => {
    setChangeLayout(e.target.value);
  };

  const onHidePopover = (value) => {
    setVisiblePopover(true);
  };

  const onCloseSaveSearch = () => {
    setSaveSearch(false);
  };

  const handItemsLimit = (value) => {
    setItemsLimit(value);
  };

  const showModal = (id) => {
    setVisible(true);
    setAssetId(id);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const cancelTableEditor = (e) => {
    setVisibleTE(false);
  };

  const showAssetLanguages = (type, data) => {
    setVisibleLanguages(true);
    setSelectedData(data);
  };

  const cancelAssetLanguages = () => {
    setVisibleLanguages(false);
  };

  const onSaveSearch = (modalType, searchRes) => {
    setSaveSearch(true);
    setSaveSearchResult(searchRes);
  };

  const showTableEditor = (value, data) => {
    setVisibleTE(true);
    setSelectionModel(value);
    setVisible(false);
    setVisiblePopover(false);
    setSelectedData(data);
  };

  const onChangePagination = (page) => {
    setCurrentPage(page);
  };

  const onFinish = (values) => {
    setCascadingSort(values.cascading_sort_filter);
    setDirectorShow(values);
    if (values) {
      setVisibleTE(false);
    }
  };

  return (
    <div>
      <InventoryFilterSection
        onChangeLayout={onChangeLayout}
        changeLayout={changeLayout}
        showTableEditor={showTableEditor}
        setChangeInventory={setChangeInventory}
        changeInventory={changeInventory}
        setSearch={setSearch}
        onSaveSearch={onSaveSearch}
        onHidePopover={onHidePopover}
        setVisiblePopover={setVisiblePopover}
        visiblePopover={visiblePopover}
        setSearchResult={setSearchResult}
        searchResult={searchResult}
        mainUserRes={mainUserRes}
      />
      {changeLayout === "LIST" && (
        <InventoryTableList
          showPreviewModal={showModal}
          showTableEditor={showTableEditor}
          pagination={changeInventory === "Both" ? true : false}
          data={
            (changeInventory === "My" &&
              assetsList &&
              addKeyInArray(assetsList.result)) ||
            (changeInventory === "Market" && marketReadyList) ||
            (changeInventory === "Both" && inventoryBothArr && inventoryBothArr)
          }
          mainUserRes={mainUserRes}
          inventoryListRes={inventoryListRes}
          orderDisorder={orderDisorder}
          setorderDisorder={setorderDisorder}
          sessionRes={sessionRes}
          showAssetLanguages={showAssetLanguages}
          updateInventoryHeaderRes={updateInventoryHeaderRes}
          onGetInventoryHeader={onGetInventoryHeader}
          getInventoryHeaderRes={getInventoryHeaderRes}
        />
      )}
      {changeLayout === "GRID" && (
        <InventoryGrid
          showModal={showModal}
          showTableEditor={showTableEditor}
          changeInventory={changeInventory}
          data={
            (changeInventory === "My" && assetsList && assetsList.result) ||
            (changeInventory === "Market" && marketReadyList) ||
            (changeInventory === "Both" && inventoryBothArr && inventoryBothArr)
          }
          sessionRes={sessionRes}
          showAssetLanguages={showAssetLanguages}
          inventoryListRes={inventoryListRes}
          onGetInventoryList={onGetInventoryList}
        />
      )}

      <Row>
        <Col span={24} className="d-flex justify-center mt30 align-item-center">
          <Pagination
            current={currentPage}
            pageSize={itemsLimit}
            onChange={onChangePagination}
            showSizeChanger={false}
            total={
              inventoryListRes &&
              inventoryListRes.assets &&
              inventoryListRes.assets.pagination &&
              inventoryListRes.assets.pagination.total
            }
          />
          <Select
            defaultValue="10"
            style={{ width: 120, marginLeft: 20 }}
            onChange={handItemsLimit}
          >
            <Option value="10">10 Items</Option>
            <Option value="30">30 Items</Option>
            <Option value="50">50 Items</Option>
            <Option value="70">70 Items</Option>
            <Option value="100">100 Items</Option>
            <Option value="300">300 Items</Option>
            <Option value="500">500 Items</Option>
            <Option value="1000">1000 Items</Option>
          </Select>
        </Col>
      </Row>
      {visible && (
        <Modal
          visible={visible}
          onCancel={handleCancel}
          footer={false}
          className="detail-model"
        >
          <InventoryModal
            assetDetail={assetDetail}
            handleCancel={handleCancel}
            showTableEditor={showTableEditor}
            sessionRes={sessionRes}
            getInventorySeachDetailRes={getInventorySeachDetailRes}
            onGetInventorySeachDetail={onGetInventorySeachDetail}
            assetId={assetId}
          />
        </Modal>
      )}

      <Modal
        visible={visibleTE}
        onCancel={cancelTableEditor}
        footer={false}
        className="modal-style"
        width={
          (selectionModel === "table_editor" && 740) ||
          (selectionModel === "add_to_selection" && 450) ||
          (selectionModel === "cascading_sort" && 500) ||
          (selectionModel === "search_preset" && 400)
        }
        centered
      >
        {selectionModel === "table_editor" && <TableColumns />}
        {selectionModel === "cascading_sort" && (
          <CascadingSort
            onFinish={onFinish}
            directorShow={directorShow}
            onGetInventoryHeader={onGetInventoryHeader}
            getInventoryHeaderRes={getInventoryHeaderRes}
          />
        )}
        {selectionModel === "add_to_selection" && (
          <SelectionModelComp
            handleCloseSelection={cancelTableEditor}
            selectedData={selectedData}
          />
        )}

        {selectionModel === "search_preset" && (
          <SavedSearchPresetsModal handleCloseSelection={cancelTableEditor} />
        )}
      </Modal>

      <LanguagesModal
        visible={visibleLanguages}
        selectedData={selectedData}
        handleCancel={cancelAssetLanguages}
      />
      <SaveSearchModal
        visible={saveSearch}
        searchResult={saveSearchResult}
        setSearchResult={setSaveSearchResult}
        handleCancel={onCloseSaveSearch}
      />
    </div>
  );
};

export default InventorySearch;
