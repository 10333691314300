import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Avatar, Upload, Button, message } from "antd";
import { exportIcon, lockIcon } from "./../../Common/Icons";
import LicenseAssetCollapse from "./LicenseAssetCollapse";
import {
  getRolesPermissions,
  getUrlSecondLastId,
  getLocalCountrytime,
  getCountrytime,
} from "./../../Common/reUseFunctions";

const LicenseContract = ({
  location,
  showModal,
  getAllTradingTaskRes,
  getFormalLicenseRes,
  onGetFormalLicense,
  selectionAssetsListRes,
  onGetSelectionAssets,
  onUploadAgreementFile,
  uploadAgreementFileRes,
  onGetLogsList,
  mainUserRes,
  getOrganizationProfileRes,
  onGetAllTradingTask,
  logoImage,
  setAssetId,
  deleteFormalLicenseFormRes,
  onDeleteFormalLicenseForm,
  assetId,
  licenseObj,
  leftOrganizationData,
  getLogsListRes,
}) => {
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  // const fileUploadProps = {
  //   name: 'file',
  //   action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
  //   headers: {
  //     authorization: 'authorization-text',
  //   },
  //   onChange(info) {
  //     if (info.file.status !== 'uploading') {
  //       console.log(info.file, info.fileList);
  //     }
  //     if (info.file.status === 'done') {
  //       message.success(`${info.file.name} file uploaded successfully`);
  //     } else if (info.file.status === 'error') {
  //       message.error(`${info.file.name} file upload failed.`);
  //     }
  //   },
  // };

  const props = {
    name: "file",
    action: "//jsonplaceholder.typicode.com/posts/",
    listType: "picture",
    // headers: {
    //   authorization: 'authorization-text',
    // },
    beforeUpload(file) {
      // const isJPG = file.type === 'image/jpeg';
      // const isJPEG = file.type === 'image/jpeg';
      // const isGIF = file.type === 'image/gif';
      // const isPNG = file.type === 'image/png';
      // const isLt1M = file.size / 1024 / 1024 < 1;
      // if (!(isJPG || isJPEG || isGIF || isPNG)) {
      //   message.error("The format of the uploaded image is incorrect, not JPG, JPEG, GIF, PNG");
      // }
      // if (!isLt1M) {
      //   message.error("Image must smaller than 1MB!");
      // }
      return new Promise((resolve, reject) => {
        if (file) {
          reject(file);
        } else {
          resolve(file);
        }
      });
    },

    onChange(info) {
      if (
        getRolesPermissions(
          mainUserRes,
          "trading-task.upload-agreement-file"
        ) === false
      ) {
        setLoading(true);
        let form = new FormData();
        form.append("agreement_file", info.file);
        setFileName(info.file.name);
        const locationUrl = location.pathname;
        const tradingTaskId = getUrlSecondLastId(locationUrl);
        if (info.file.type === "application/pdf") {
          onUploadAgreementFile(tradingTaskId, form).then(() => {
            message.success("File is uploaded successfully");
            setLoading(false);
            onGetLogsList(tradingTaskId).then(() => {
              onGetAllTradingTask(tradingTaskId);
            });
          });
        } else {
          message.error(
            "The format of the uploaded image is incorrect, not PDF File"
          );
        }
      } else {
        message.error("You Don't Have The Permission");
      }
    },
  };

  return (
    <Fragment>
      <div className="license-contract">
        <div className="contract-header">
          <Row gutter={16}>
            <Col span={10}>
              <div className="contract-asset-profile">
                <Avatar src={logoImage} size={60} />
                <div>
                  <h3>
                    {getAllTradingTaskRes &&
                      getAllTradingTaskRes.data &&
                      getAllTradingTaskRes.data.assigned_to_object &&
                      getAllTradingTaskRes.data.assigned_to_object.name}
                  </h3>
                  <p>
                    {leftOrganizationData.created_by_company_title ||
                      leftOrganizationData.company_title}
                    {/* ,{" "}
                    {getOrganizationProfileRes &&
                      getOrganizationProfileRes.country}{" "}
                    {getOrganizationProfileRes &&
                      getOrganizationProfileRes.timezone} */}
                  </p>
                </div>
              </div>
            </Col>
            <Col className="contract-time" span={6}>
              <p>
                {getOrganizationProfileRes && getOrganizationProfileRes.city},
                Local time {getCountrytime(getOrganizationProfileRes)}
              </p>
            </Col>
            <Col className="contract-time" span={6}>
              <p>Your Local time {getLocalCountrytime()}</p>
            </Col>
          </Row>
        </div>
        <div className="contract-upload">
          <h2>
            {exportIcon}{" "}
            {getAllTradingTaskRes &&
              getAllTradingTaskRes.data &&
              getAllTradingTaskRes.data.name}
          </h2>
          <h3 className="mb20">LICENSE CONTRACT</h3>
          {getRolesPermissions(
            mainUserRes,
            "trading-task.upload-agreement-file"
          ) === false ? (
            <div className="upload-btn-style">
              <Upload {...props}>
                <Button loading={loading}>
                  {lockIcon} Select Agreement File
                </Button>
              </Upload>
              <span className="m-10">
                {fileName ? fileName : "No File Selected"}
              </span>
            </div>
          ) : (
            ""
          )}

          <p>
            The agreement will only be shared with your contractor and is not
            stored on any platform server.
          </p>
          {/* <Button className="upload-history" type="link" onClick={() => showModal('file_history')}><HistoryOutlined /> File history</Button> */}
        </div>
        <div>
          <LicenseAssetCollapse
            mainUserRes={mainUserRes}
            showModal={showModal}
            getAllTradingTaskRes={getAllTradingTaskRes}
            getFormalLicenseRes={getFormalLicenseRes}
            onGetFormalLicense={onGetFormalLicense}
            location={location}
            selectionAssetsListRes={selectionAssetsListRes}
            onGetSelectionAssets={onGetSelectionAssets}
            setAssetId={setAssetId}
            deleteFormalLicenseFormRes={deleteFormalLicenseFormRes}
            onDeleteFormalLicenseForm={onDeleteFormalLicenseForm}
            assetId={assetId}
            licenseObj={licenseObj}
            getOrganizationProfileRes={getOrganizationProfileRes}
            onGetAllTradingTask={onGetAllTradingTask}
            uploadAgreementFileRes={uploadAgreementFileRes}
            getLogsListRes={getLogsListRes}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default LicenseContract;
