import React, { useState } from "react";
import {
  Row,
  Col,
  message,
  Upload,
  Button,
  Popover,
  Input,
  Form,
  Tabs,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { getRolesPermissions } from "../../../../Common/reUseFunctions";
import { QuestionCircleFilled } from "@ant-design/icons";

const { TabPane } = Tabs;
const urlRegex =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const ISCCGenerate = ({
  assetID,
  assetDetail,
  onGetAssetDetail,

  onIsccCodeGeneration,
  isccGeneration,

  onIsccGenerationProcess,
  isccStatus,
  mainUserRes,
  setTitleEdit,
  form,
  formUrlIsccGenerationRes,
  onPutformUrlIsccGeneration,
}) => {
  const [loading, setLoading] = useState(false);
  const [isText, setIsText] = useState("");

  // useEffect(() => {
  //   if (
  //     assetDetail &&
  //     assetDetail.iscc_code &&
  //     assetDetail.iscc_code.status === "processing"
  //   ) {
  //     checkingIsccGenerationProcess(assetDetail.iscc_code.process);
  //   }
  // }, [assetDetail]);

  // const checkingIsccGenerationProcess = (processId) => {
  //   setLoading(true);
  //   let interval = setInterval(() => {
  //     onIsccGenerationProcess(processId).then((res) => {
  //       if (res.payload && res.payload.status === "completed") {
  //         clearInterval(interval);
  //         setLoading(false);
  //         onGetAssetDetail(assetID);
  //       }
  //       if (
  //         res.type === "ISCC_CODE_GENERATION_PROCESS_SUCCESS" &&
  //         res.payload.result &&
  //         res.payload.result.from &&
  //         res.payload.result &&
  //         res.payload.result.transactionHash
  //       ) {
  //         const id = assetDetail && assetDetail._id;
  //         const dataSig = {
  //           wallet: res.payload.result.from,
  //           tx: res.payload.result.transactionHash,
  //         };
  //         onUpdateAsset(id, dataSig)
  //           .then(() => {
  //             onGetAssetDetail(id);
  //             clearInterval(interval);
  //           })
  //           .catch(() => {
  //             clearInterval(interval);
  //           });
  //       }
  //     });
  //   }, 5000);
  // };

  const onChangeWithFile = async (info) => {
    setLoading(true);
    if (info.file.status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === "done") {
      let isccFile = new FormData();
      isccFile.append("title", info.fileList[0].name);
      isccFile.append("file", info.fileList[0].originFileObj);
      isccFile.append("extra", "");
      if (getRolesPermissions(mainUserRes, "asset.iscc") === false) {
        await onIsccCodeGeneration(assetID, isccFile).then(async (res) => {
          if (res && res.type === "ISCC_CODE_GENERATION_SUCCESS") {
            // await onGetAssetDetail(assetID);
            // getHitUpadateButton();
            message.success(res && res.payload && res.payload.message);
            setTitleEdit(true);
            setTitleEdit(false);
            setTimeout(() => {
              onGetAssetDetail(assetID).then(() => {
                setLoading(false);
              }).catch(() => {
                setLoading(false);
              })
            }, 8000);
          }
        });
      } else {
        message.error("You Don't Have Permission");
        setLoading(false);
        if (info.file.status === "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      }
    } else {
      setLoading(false);
    }
  };

  const onChangeWithUrl = async (info) => {
    setLoading(true);
    let isccUrl = new FormData();
    isccUrl.append("title", info);
    isccUrl.append("url", info);
    isccUrl.append("extra", "");
    if (getRolesPermissions(mainUserRes, "asset.iscc") === false) {
      await onPutformUrlIsccGeneration(assetID, isccUrl)
        .then(async (res) => {
          if (res && res.type === "POST_ISCC_CODE_URL_SUCCESS") {
            // await onGetAssetDetail(assetID);
            // getHitUpadateButton();
            message.success(res && res.payload && res.payload.message);
            // form.resetFields();
            setTitleEdit(true);
            setTitleEdit(false);
            setTimeout(() => {
              onGetAssetDetail(assetID).then(() => {
                setLoading(false);
              }).catch(() => {
                setLoading(false);
              })
            }, 8000);
          }
        })
        .catch((error) => {
          setLoading(false);
        });
    } else {
      message.error("You Don't Have Permission");
      setLoading(false);
      if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    }
  };

  const beforeUpload = (file) => {
    // const isJPG = file.type === "image/jpg";
    // const isJPEG = file.type === "image/jpeg";
    // const isPNG = file.type === "image/png";
    // const isMp4 = file.type === "video/mp4";
    // const isMOV = file.type === "video/mov";
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!(isJPG || isJPEG || isPNG || isMp4 || isMOV)) {
    //   message.error(
    //     "The format of the uploaded image is incorrect, not JPG, JPEG, PNG, MP4"
    //   );
    // }
    // if (!isLt2M) {
    //   message.error("Image or Video must smaller than 2MB!");
    // }
    // return new Promise((resolve, reject) => {
    //   if (!(isJPG || isJPEG || isPNG || isMp4 || isMOV) || !isLt2M) {
    //     reject(file);
    //   } else {
    //     resolve(file);
    //   }
    // });
  };

  const uploadService = () => {
    return Promise.resolve();
  };

  const customRequest = async (options) => {
    options.onProgress({ percent: 0 });
    const url = await uploadService();
    options.onProgress({ percent: 100 });
    options.onSuccess({}, { ...options.file, url });
    console.log(options);
  };

  const getHitUpadateButton = () => {
    const getAssetUpdate = document.getElementById("update-asset-id");
    if (getAssetUpdate) {
      getAssetUpdate.click();
    }
  };

  return (
    <Row gutter={16} className="mb5">
      <Col span={24}>
        <Spin size="large" spinning={loading}>
          <div className="metadata-field-row">
            <div className="metadata-field-name">
              <h3 className="required">
                <span>ISCC</span>
              </h3>
            </div>
            <div className="metadata-filed tabs-style">
              {
              (assetDetail &&
                assetDetail.iscc_code &&
                assetDetail.iscc_code.status === "complete") ? (
                <div className="iscc-generated">ISCC Generated</div>
              ) : (
                <Tabs defaultActiveKey="1">
                  <TabPane tab="From File" key="1">
                    {getRolesPermissions(mainUserRes, "asset.iscc") ===
                    false ? (
                      <div className="upload-iscc-btn d-flex">
                        <div className="iscc-merge-buttons">
                          <div className="iscc-merge-buttons-upload">
                            <Upload
                              onChange={onChangeWithFile}
                              customRequest={customRequest}
                              beforeUpload={beforeUpload}
                            >
                              <Button
                                loading={loading}
                                icon={<UploadOutlined />}
                              >
                                Select File
                              </Button>
                            </Upload>
                          </div>
                          <div className="iscc-merge-buttons-info">
                            <Popover
                              overlayStyle={{
                                width: "30rem",
                                textAlign: "left",
                              }}
                              content={
                                <div className="iscc-merge-buttons-info-content">
                                  <div className="iscc-merge-buttons-info-content-heading">
                                    <strong>
                                      Upload requirements + notes on ISCC
                                      generation:
                                    </strong>
                                  </div>
                                  <ul>
                                    <li>
                                      use a single media file in{" "}
                                      <strong>.mp4, .mov</strong> file format
                                      and <strong>smaller than 1,000 MB</strong>
                                    </li>
                                    <li>
                                      <strong>
                                        don't leave the asset editor page until
                                        file upload is completed
                                      </strong>
                                    </li>
                                    <li>
                                      after successful file upload, the{" "}
                                      <strong>
                                        ISCC code is being generated in the
                                        background
                                      </strong>
                                      . This can take up to 60 min. You can
                                      leave the page in the meantime and check
                                      on the ISCC code generation status in the
                                      Asset Checklist sidebar
                                    </li>
                                    <li>
                                      <strong>
                                        only one ISCC code can be generated at a
                                        time
                                      </strong>
                                    </li>
                                  </ul>
                                </div>
                              }
                              trigger="click"
                            >
                              <QuestionCircleFilled />
                            </Popover>
                          </div>
                        </div>
                        <p className="upload-msg">
                          Please{" "}
                          <a href="mailto:support@contentagent.net">
                            contact us
                          </a>{" "}
                          if your assets are either hosted in your cloud or with
                          an external hosting service.
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                  </TabPane>
                  <TabPane tab="From URL" key="2">
                    <div className="d-flex">
                      <Form.Item
                        name="Iscc-Url"
                        rules={[
                          {
                            pattern: urlRegex,
                            message: "Url is not valid",
                          },
                        ]}
                        className="url-form"
                      >
                        <Input
                          value={isText}
                          onChange={(e) => setIsText(e.target.value)}
                          placeholder="Video URL"
                        />
                      </Form.Item>
                      <Form.Item className="apply-url-btn">
                        {isText.match(urlRegex) ? (
                          <Button
                            loading={loading}
                            onClick={() => onChangeWithUrl(isText)}
                            type="primary"
                            className="code-generator ml-20"
                          >
                            Apply
                          </Button>
                        ) : (
                          <Button
                            disabled={true}
                            type="primary"
                            className="code-generator ml-20 btn-grey"
                          >
                            Apply
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </TabPane>
                </Tabs>
              )}
            </div>
          </div>
        </Spin>
      </Col>
    </Row>
  );
};

export default ISCCGenerate;
